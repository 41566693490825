<template>
    <div class='layout-dashboard' v-if='visibleToEditor'>
        <div class='grid'>
            <div class='col-12 md:col-6 xl:col-3 pointer' @click='$router.push({ name: "cont_books_list"})'>
                <div class='card no-gutter widget-overview-box widget-overview-box-1'>
                    <span class='overview-icon'>
                        <i class='pi pi-book'></i>
                    </span>
                    <span class='overview-title'>Libri</span>
                    <div class='grid overview-detail'>
                        <div class='col-6' v-for='(language, code) in languages' :key="'bl_'+code">
                            <div class='overview-number'>
                                <Flag :code='code' :name='language.label' :width='24' :class="{'mr-1': true}"></Flag>
                                {{ language.label }}
                            </div>
                            <div class='overview-subtext text-left'>
                                <p>{{ statFiltered('books', 'Published', code) }} Pubblicati</p>
                                <p>{{ statFiltered('books', 'ToBePublished', code) }} In pubblicazione</p>
                                <p>{{ statFiltered('books', 'Ready', code) }} Pronti</p>
                                <p>{{ statFiltered('books', 'Draft', code) }} Bozze</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='col-12 md:col-6 xl:col-3 pointer' @click='$router.push({ name: "cont_articles_list"})'>
                <div class='card no-gutter widget-overview-box widget-overview-box-3'>
                    <span class='overview-icon'>
                        <i class='pi pi-file'></i>
                    </span>
                    <span class='overview-title'>Articoli</span>
                    <div class='grid overview-detail'>
                        <div class='col-6' v-for='(language, code) in languages' :key="'ba_'+code">
                            <div class='overview-number'>
                                <Flag :code='code' :name='language.label' :width='24' :class="{'mr-1': true}"></Flag>
                                {{ language.label }}
                            </div>
                            <div class='overview-subtext text-left'>
                                <p>{{ statFiltered('articles', 'Published', code) }} Pubblicati</p>
                                <p>{{ statFiltered('articles', 'ToBePublished', code) }} In pubblicazione</p>
                                <p>{{ statFiltered('articles', 'Ready', code) }} Pronti</p>
                                <p>{{ statFiltered('articles', 'Draft', code) }} Bozze</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='col-12 md:col-6 xl:col-3 pointer' @click='$router.push({ name: "cont_the_update_list"})'>
                <div class='card no-gutter widget-overview-box widget-overview-box-2'>
                    <span class='overview-icon'>
                        <i class='pi pi-clock'></i>
                    </span>
                    <span class='overview-title'>The Update</span>
                    <div class='grid overview-detail'>
                        <div class='col-6' v-for='(language, code) in languages' :key="'btu_'+code">
                            <div class='overview-number'>
                                <Flag :code='code' :name='language.label' :width='24' :class="{'mr-1': true}"></Flag>
                                {{ language.label }}
                            </div>
                            <div class='overview-subtext text-left'>
                                <p>{{ statFiltered('the_updates', 'Published', code) }} Pubblicati</p>
                                <p>{{ statFiltered('the_updates', 'ToBePublished', code) }} In pubblicazione</p>
                                <p>{{ statFiltered('the_updates', 'Ready', code) }} Pronti</p>
                                <p>{{ statFiltered('the_updates', 'Draft', code) }} Bozze</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='col-12 md:col-6 xl:col-3 pointer' @click='$router.push({ name: "cont_quotes_list"})'>
                <div class='card no-gutter widget-overview-box widget-overview-box-4'>
                    <span class='overview-icon'>
                        <i class='pi pi-comment'></i>
                    </span>
                    <span class='overview-title '>Citazioni</span>
                    <div class='grid overview-detail'>
                        <div class='col-6' v-for='(language, code) in languages' :key="'bq_'+code">
                            <div class='overview-number'>
                                <Flag :code='code' :name='language.label' :width='24' :class="{'mr-1': true}"></Flag>
                                {{ language.label }}
                            </div>
                            <div class='overview-subtext text-left'>
                                <p>{{ statFiltered('quotes', 'Published', code) }} Pubblicati</p>
                                <p>{{ statFiltered('quotes', 'ToBePublished', code) }} In pubblicazione</p>
                                <p>{{ statFiltered('quotes', 'Ready', code) }} Pronti</p>
                                <p>{{ statFiltered('quotes', 'Draft', code) }} Bozze</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class='grid'>
            <div class='col-12 md:col-6'>
                <div class='card no-gutter widget-overview-box widget-overview-box-1'>
                    <span class='overview-icon pointer' @click='getBooksXCat(false)'>
                        <i class='pi pi-book'></i>
                    </span>
                    <span class='overview-title'>Libri pubblicati per categoria</span>
                    <i class='pi pi-refresh pointer' title='Aggiorna anche i likes' style='float:right' @click='getBooksXCat(true)'></i>
                    <div class='grid overview-detail'>
                        <Panel :toggleable='true' class='col-12' v-for='(language, code) in languages'
                               :key="'bpl_'+code" :collapsed='true'>
                            <template #header>
                                <Flag :code='code' :name='language.label' :width='24' :class="{'mr-1': true}"></Flag>
                                {{ language.label }}
                            </template>
                            <div class='overview-subtext text-left' v-if='!loading_books_x_cat'>
                                <DataTable :value='books_x_cat_x_lang(code)' responsiveLayout='scroll'
                                           class='p-datatable-sm'>
                                    <Column :sortable='true' field='category_name' header='Categoria'></Column>
                                    <Column :sortable='true' field='num' header='Num'></Column>
                                    <Column :sortable='true' field='likes' header='Likes'></Column>
                                    <Column :sortable='true' field='dislikes' header='Dislikes'></Column>
                                </DataTable>
                            </div>
                            <div class='overview-subtext text-left' v-else>
                                Loading data....
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
            <div class='col-12 md:col-6'>
                <div class='card no-gutter widget-overview-box widget-overview-box-3'>
                    <span class='overview-icon pointer'  @click='getArticlesXCat(false)'>
                        <i class='pi pi-file'></i>
                    </span>
                    <span class='overview-title'>Articoli pubblicati per categoria</span>
                    <i class='pi pi-refresh pointer' title='Aggiorna anche i likes' style='float:right' @click='getArticlesXCat(true)'></i>
                    <div class='grid overview-detail'>
                        <Panel :toggleable='true' class='col-12' v-for='(language, code) in languages'
                               :key="'apl_'+code" :collapsed='true'>
                            <template #header>
                                <Flag :code='code' :name='language.label' :width='24' :class="{'mr-1': true}"></Flag>
                                {{ language.label }}
                            </template>
                            <div class='overview-subtext text-left' v-if='!loading_articles_x_cat'>
                                <DataTable :value='articles_x_cat_x_lang(code)' responsiveLayout='scroll'
                                           class='p-datatable-sm'>
                                    <Column :sortable='true' field='category_name' header='Categoria'></Column>
                                    <Column :sortable='true' field='num' header='Num'></Column>
                                    <Column :sortable='true' field='likes' header='Likes'></Column>
                                    <Column :sortable='true' field='dislikes' header='Dislikes'></Column>
                                </DataTable>
                            </div>
                            <div class='overview-subtext text-left' v-else>
                                Loading data....
                            </div>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h4>4mind MVP</h4>
    </div>
</template>

<script>
import Notifications from '../../mixins/notifications';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters('Common', ['languages']),
        ...mapGetters('Dashboard',
            ['books', 'articles', 'the_updates', 'quotes', 'loading_books_x_cat',
                'books_x_cat_x_lang', 'loading_articles_x_cat', 'articles_x_cat_x_lang']),
        ...mapGetters('Authn', ['role']),
        visibleToEditor(){
            return ['admin','super-admin', 'editor'].includes(this.role);
        }
    },
    mixins: [
        Notifications,
    ],
    mounted() {
        if (this.visibleToEditor){
            this.refreshStats();
            this.getBooksXCat(false);
            this.getArticlesXCat(false);
        }
    },
    methods: {
        ...mapActions('Dashboard', ['getStats', 'getBooksXCat', 'getArticlesXCat']),
        refreshStats() {
            this.getStats().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        statFiltered(el, key, lang) {
            if (!this[el]) return 0;
            if (!this[el].length) return 0;
            const filtered = this.langFiltered(this[el], lang);
            if (!filtered[key]) return 0;
            return filtered[key];
        },
        langFiltered: (rows, lang) => {
            let el = {};
            rows.forEach(row => {
                if (row['Locale'] === lang) {
                    el = row;
                }
            });
            return el;
        },

        menuToggle($event) {
            this.$refs.menu.toggle($event);
        },

        formatCurrency(value) {
            return value.toLocaleString('it-IT', {
                style: 'currency',
                currency: '€',
            });
        },
    },
};
</script>

<style lang='scss' scoped>
.layout-dashboard {
    img.flag {
        width: 24px !important;
        margin-top: -3px;
    }

    .overview-subtext {
        p {
            margin: 0 0 0 0;
            line-height: normal;
        }
    }
    .widget-overview-box {
        &.widget-overview-box-1,
        &.widget-overview-box-2,
        &.widget-overview-box-3,
        &.widget-overview-box-4{
            .overview-icon{
                color: white;
            }
        }
    }
}
</style>
